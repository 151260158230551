.ql-toolbar.ql-snow + .ql-container.ql-snow{
    max-height: 170px;
}
.ql-toolbar.ql-snow,.ql-container.ql-snow{
    /* background-color: #fff; */
}
.ql-snow .ql-formats{
    background: #f4f4f4;
    border-radius: 5px;
}

.ql-editor strong{
    font-weight:bold !important;
}
.ql-editor p {
    color: black;
}

.quill {
    display: flex;
    flex-direction: column-reverse;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none;
}