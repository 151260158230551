.home-input-section {
    background: #C4C4C433 !important;
    width: 325px;
    height: 50px;
    border-radius: 100px;
    padding: 1rem;

}

.home-input-section>input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    padding-left: 0.5rem;
}

.gap {
    gap: 1rem;
}
.card{
    box-shadow: 0px 4px 20px 0px #001E521A;
    border: none;
    border-radius: 20px !important;
}