.main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    /* background-color: aliceblue; */
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    width: 100%;
    padding-inline: 7rem;
    /* padding: 4rem; */
    /* min-width: 65%; */
}

.left-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}


.left-side h1 {
    font-weight: 700;
    font-size: 40px;
    color: #484848;
}

.left-side .left-upper-section div {
    font-weight: 400;
    font-size: 20px;
    color: #838383;
    margin-top: 5px;
}

.form-floating {
    position: relative;
}

.form-floating .halfeye-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.form-floating #floatingInput,
.form-floating #floatingPassword {
    border-radius: 12px !important;
}

/* Target autofill background for the email input */
#floatingInput:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
}

/* Reset background color for the email input */
#floatingInput:focus {
    background-color: transparent !important;
}

/* Target autofill background for the password input */
#floatingPassword:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
}

/* Reset background color for the password input */
#floatingPassword:focus {
    background-color: transparent !important;
}

.common-style {
    font-size: 12px;
    font-weight: 400;
    color: #838383;
    ;
}

.right-side {
    width: 500px;
    height: 507px;
}

.right-side img {
    height: 100%;
    width: 100%;
}

.last-div-element {
    font-weight: 400;
    font-size: 14px;
    color: #838383;

}

.last-div-element p span {
    font-weight: 600;
}

.help-text {
    z-index: 99;
    color: rgba(77, 142, 255, 1);
    font-size: 12px;
    cursor: pointer;
}