.img-drop-container {
    width: 100%;
    border: 2px dashed #979797;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 40px 20px;
}

.dropzone-containe #imgFile{
cursor: pointer;
}

.img-drop-container .drag-label {
    color: #0258a1;
    padding: 3px 0;
    font-size: 20px;
    font-weight: 500;
}
.img-drop-container .drag-description{
  color: #979797;
  font-size: 14px;
  font-weight: 400;
}
.data-type-icon {
    background-color: rgba(77, 142, 255, 1);
    color: white;
    height: 37.83px;
    width: 37.83px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.custom-overlay-class {
    z-index: 10001; /* Set the desired z-index value */
  }
  
  /* Add your custom styles for the modal */
  .custom-modal-class {
    z-index: 10002; /* Set the desired z-index value */
  }
  .doc-name{
font-size: 12px;
  }


/* 
 */
 .pagination .page-item a {
    border-radius: 50%;
    border: none;
    color: #838383;
    font-size: 12px;
  }
  .pagination .page-item.active a {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    color: white;
  }
  
  .pagination .page-item {
    /* padding-inline: 5px; */
  }
  .pagination .page-item.active a:hover {
    background-color: #0258a1;
  }
  .pagination .page-item.active a:focus {
    box-shadow: none;
  }
  .pagination .page-item a:hover{
  background-color: inherit;
  }
  
  .pagination .page-link {
    background-color: none;
  }
  
  .pagination .prev,
  .pagination .next {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #0258a1;
    /* Customize background color */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: 10px !important;
  }
  
  /* Remove border around pagination links */