@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;

}

.spinner-component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
}

:root {
  --primary: #0258a1;
  --secondary: #838383;
}

.default-text-color {
  color: #0258a1;
}

.default-text-background-color {
  background-color: var(--primary);
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-justify-end {
  display: flex !important;
  justify-content: flex-end !important;
}

/* Modal is Open */
.react-confirm-alert .react-confirm-alert-body {
  font-size: 12px;
}

.react-confirm-alert .react-confirm-alert-body h1 {
  font-size: 22px;
  margin-bottom: 7px;
}

.react-confirm-alert .react-confirm-alert-body .react-confirm-alert-button-group button {
  background-color: var(--primary);
}

/*toggle  */
/* toggle */
.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  width: fit-content;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 90px;
  height: 30px;
  padding: 0;
  border-radius: 30px;
  background-color: #EC6666;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #eb4f4f;
}

.react-toggle--checked .react-toggle-track {
  background-color: rgba(61, 213, 152, 1);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgba(61, 213, 152, 1);
}

.react-toggle-track-check {
  position: absolute;
  width: 70px;
  height: 5px;
  top: 5px;
  bottom: 0px;
  margin-top: auto;
  color: white;
  margin-bottom: auto;
  line-height: 0;
  left: 20px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 60px;
  height: 10px;
  left: 30px;
  top: 10px;
  bottom: 0px;
  padding-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  color: white;
  right: 5px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 4px;
  left: 5px;
  width: 22px;
  height: 22px;
  border: 1px solid #EC6666;
  border-radius: 50%;
  background-color: #FAFAFA;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 63px;
  border-color: rgba(61, 213, 152, 1);
}

/* .react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px rgba(61, 213, 152, 1);
  -moz-box-shadow: 0px 0px 3px 2px rgba(61, 213, 152, 1);
  box-shadow: 0px 0px 2px 3px rgba(61, 213, 152, 1);
} */

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px rgba(61, 213, 152, 1);
  -moz-box-shadow: 0px 0px 5px 5px rgba(61, 213, 152, 1);
  box-shadow: 0px 0px 5px 5px rgba(61, 213, 152, 1);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-div-style {
  height: 2.7rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.page-heading {
  font-size: 27px;
  font-weight: 500;
  color: var(--primary);
}

.card-default-style {
  min-height: 106px;
  box-shadow: 0px 4px 17px 0px #7979791A;
  background-color: white;
  border-radius: 12px;
}

.default-color {
  color: var(--primary);
}

.curriculum-assign-status {
  padding: 7px 8px 7px 8px;
  border: 1px solid;
  font-size: 14px;
  text-wrap: nowrap;
}

.status-pending-color {
  color: #FFC263;
  border-color: #FFC263;
  background-color: rgba(255, 194, 99, 0.1);
}

.status-assign-color {
  color: #3DD598;
  border-color: #3DD598;
  background: rgba(61, 213, 152, 0.1);
}

.status-rejected-color {
  color: #EC6666;
  border-color: #EC6666;
  background: rgba(236, 102, 102, 0.1);
}

.react-confirm-alert .react-confirm-alert-body {
  font-size: 17px;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 476px;
  padding: 30px;
  text-align: left;
  height: 196px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert .react-confirm-alert-body h1 {
  font-size: 33px;
  margin-bottom: 7px;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 41px;
}

.react-confirm-alert-button-group>button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 12px 27px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body>h1 {
  margin-top: 0;
}

.react-confirm-alert-body>h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group>button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.MuiDrawer-paperAnchorRight {
  width: 50% !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.invoice-list-dataTable .rdt_TableHeadRow {
  border-radius: 4px 4px 0px 0px !important;
}

#input-component {
  height: 56px;
  border-radius: 10px
}
.form-label{
  color: #6D7278;
  font-size: 14px;
}
.mandatory {
  color: red;
  font-size: .857rem;
  margin-left: 2px;
}
.drawer-heading {
  color: var(--primary);
  font-size: 28px;
}
.drawer-heading-container {
  display: flex;
  padding: 1.5rem;
  gap: 10px;
  align-items: center;
  color: var(--primary);
}

.Dropdown-container > .css-3iigni-container > .css-16xfy0z-control {
  height: 56px;
  border-radius: 10px
}