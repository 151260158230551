.data-type-icon {
    background-color: rgba(77, 142, 255, 1);
    color: white;
    height: 30.83px;
    width: 30.83px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 5px; */
}

.content-card-container .cross-icon-container {
    border: 1px solid rgba(196, 196, 196, 1);
    width: 29px;
    height: 29px;
    border-radius: 50%;
    color: rgba(131, 131, 131, 1);
    position: absolute;
    top: 5px;
    right: 6px;
    background-color: white;
}



.content-card-container {
    /* padding: 11px; */
    width: 100%;
    height: 77px;
    border-radius: 8px;
    border: 0.5px;
    /* box-shadow: 0px 0px 5px 0px rgba(0, 27, 74, 0.2); */
    /* border: 0.5px solid rgba(196, 196, 196, 1); */
    overflow: hidden;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
}
.check-btn {
    background: none;
}
.check-btn >.custom-select-btn-secondary:hover {
background: var(--primary);
color: white;
}