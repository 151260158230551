.custom-input-inc-dec {
    border: 1px solid rgba(192, 192, 192, 1);
    border-radius: 8px;
    padding: 5px 7px;
    display: flex;
    align-items: center;
    background-color: #fff;
}

.custom-input-inc-dec input {
    border: none;
    outline: none;
    font-size: 18px;
    color: rgba(131, 131, 131, 1);
    font-weight: 500;
    width: 45px;
    text-align: center;
}

.custom-input-inc-dec .custom-input-title {
    background-color: rgba(243, 243, 243, 1);
    font-size: 18px;
    font-weight: 500;
    border-radius: 8px;
    padding: 3px;
    margin-right: 5px;
}

.custom-input-inc-dec .plus-minus-icons {
    color: rgba(216, 216, 216, 1);
}

.custom-input-inc-dec .plus-minus-icons:hover {
    color: var(--primary);
}