.search-input {
    display: flex;
    align-items: center;
    /* width: 200px; */
    height: 40px;
    border-radius: 100px;
    background-color: #f5f5f5;
    padding: 0 15px;
    margin: 5px;
    /* Adjust margin as needed */
}

.search-input input {
    /* flex: 1; */
    border: none;
    outline: none;
    background: transparent;
    width: 7rem;
    font-size: 14px;
    /* margin-left: 10px;  */
}

.search-input svg {
    /* margin-right: 10px; */
    /* Adjust margin as needed */
}

.search-input .rotateCw {
    width: 24px;
    height: 24px;
   display: flex;
   justify-content: center;
   align-items: center;
}