.grid-list-container {
    justify-content: center;
    box-shadow: 0px 4px 20px 0px #001E521A;
    background-color: #FFFFFF;
    border-radius: 8px;
    height: 43px;
    width: 86px;
    /* padding: 1rem; */
    margin-bottom: 1rem;
}
