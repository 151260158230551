.main_curriculum_section{
    flex-direction: row-reverse;
    gap: 20px;
}
.new-curriculam-first-part-section {
    /* border-left: 0.5px solid #A7A7A7; */
    min-width: 70%;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 99;
    box-shadow: 0px 7px 20px 0px #00000014;
    border-radius: 12px;
}
.basic_details_img,.basic_details_img img{
    width: 100%;
    /* height: 68vh; */
    margin: auto;
    text-align: center;
}
.basic_details_img {
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 75vh;
}
.basic_details_img img{
    width: 300px;
}

.demo-img {
    /* height: 68vh; */
}
.basic-details-content-div {
    border-bottom: 0.5px solid #A7A7A7
}

.details-section {
    flex: 1;
    /* background-color: #838383; */
    /* height: 50px; */
    /* overflow: hidden; */
    z-index: 100;
}

.new-curriculam-first-part-section .main_curriculum_tab {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 1rem;
    margin: 0.5rem 1.5rem 0;
}

.new-curriculam-sidebar-last {
    /* background-color: aqua; */
    align-items: end;
    border-top: 0.5px solid #A7A7A7;
    padding: 1.5rem;
}

.new-curriculam-sidebar-last .save-as-draft-container{
font-size: 18px;
font-weight: 400;
color: #838383;
box-shadow: 0px 4px 12px 0px #002D7D33;
border-radius: 10px;
display: flex;
align-items: center;
gap: 5px;
margin-bottom: 15px;
border: none;
background-color: white;
width: 100%;
}

.new-curriculam-sidebar-last span {
    margin: 0;
    padding: 0;
}

.new-curriculam-second-part-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 7px 20px 0px #00000014;
    height: 100% !important;
    border-radius: 12px;
}

.new-curriculam-second-part-section p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #838383;
    margin-top: 0.5rem;
    
    /* position: relative; */
    /* white-space: nowrap;
    overflow: hidden;
    text-wrap: nowrap; */
    /* min-width: 1000px !important; */
    /* overflow: scroll !important; */

}

 .media-link-icon-container {
    background-color: #F3F3F3;
    border-radius: 8px;
    cursor: pointer;
}

.add-curriculam-section-div {
    background-color: #EDF4FF;
    padding: 0.4rem;
    border-radius: 10px;
    color: #838383;
    margin-right: 10px;
}

.add-curriculam-section-div h2 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    margin: 0;

}

.private-public-span {
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #838383;
}

.setcurriculam-span {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: #C4C4C4;

}

.curriclam-side-input-section {
    border: 1px solid #C0C0C0;
    outline: none;
    border-radius: 10px;
    padding: 1rem;
}
.curriclam-side-input-section:focus{
    border: 1px solid #0258a1;
}
.curriculam-side-subject-section {
    /* border: 1px solid #C0C0C0; */
    /* border-radius: 10px; */
    padding: 0.5rem 1rem;
    /* border-radius: 4px; */
    /* border: 1px solid #C0C0C0; */
    background-color: #fff;
    gap: 10px;
    position: relative;
}
.main_add_subject_section {
    background-color: #f4f4f4;
    padding: 15px;
    margin: 15px;
    border-radius: 15px;
}
.pop-over-files-details {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;

}

.hidebox {
    display: none !important;
    transition: all 0.7ms
}

/* Curriculam Section Modal */
.curriculam-modal-section-part1,
.curriculam-modal-section-part2{
    line-height: 21.94px;
}
.curriculam-modal-section-part1 p,
.curriculam-modal-section-part2 p {
    margin: 0;
}

.curriculam-modal-section-part1 h2,
.curriculam-modal-section-part2 h2 {
    font-size: 26px;
    font-weight: 500;
}

.curriculam-modal-section-part1 p,
.curriculam-modal-section-part2 p {
    color: #838383;
}

.curriculam-modal-section-part1 p span,
.curriculam-modal-section-part2 p span {
    color: #1D1D17;
}

.icon-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 48px;
    border-radius: 4px;
    /* border-radius: 8px; */
    background-color: #0258a1;
    color: white;
}

.curriculam-side-subject-cusInput input {
    border: none;
    outline: none;
    border-left: 2px solid #C0C0C0;
    padding-left: 10px;
    border-radius: 4px;

}

.curriculam-side-subject-cusInput-active {
    /* border: 1px solid #0258a1; */
    /* border-radius: 4px; */
}

.curriculam-side-subject-cusInput .trash2-icon {
    background-color: #F3F3F3;
    color: #649DFF;
    width: 31.02px;
    height: 31.02px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.trash2-icon > svg {
    stroke: var(--primary);
}
.sub-topic-div{
    margin-left: 20px;
}
/* New css start  */
.add_topics_padding {
    padding-left: 63px;
}
.add_topics_items{
    padding-left: 78px;
}
.subject_counter {
    min-width: 40px;
    background: #f4f4f4;
    padding: 10px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.subject_counter strong,.topic_counter strong,.sub_topic_counter strong {
    font-weight: 600;
}
.topic_counter,.sub_topic_counter {
    background: #f4f4f4;
    padding: 5px;
    border-radius: 5px;
}
.subject_heading_div {
    color: rgba(131, 131, 131, 1);
    font-size: 15px;
    font-weight: 400;
    /* border-bottom: 0.5px solid rgba(167, 167, 167, 1); */
    position: relative;
}
.subject_name_preview,.topics_preview_heaing,.subtopic_preview_heading {
    padding-left: 10px;
}
.subject_preview_heading .dropdown_arrow,.topic-preview .dropdown_arrow{
    position: absolute;
    right: 5px;
}
.subject_preview_heading{
    font-size: 15px;
}
.cursor-pointer{
    cursor: pointer;
}
.subtopic_description_field,.subtopic_prerequisites_field {
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    min-height: 100px;
    border: 1px solid #C0C0C0;
    outline: none;
}
.subtopic_description_field:focus,.subtopic_prerequisites_field:focus{
    border-color: #0258a1;
}
.textarea_field_div{
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}
.choose_subtopic_activity {
    background: #ffffff;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    border-color: #0258a1;
    color: #0258a1;
    outline: none;
}
/* add action Button */
.add-actions-btn {
    background: #ffffff;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    border-color: #0258a1;
    color: #0258a1;
    outline: none;
    cursor: pointer;
    min-width: max-content;
    outline: none;
    border: 1px solid;
}
.add-actions-btn svg {
    width: 12px;
}
.dropdown-menu {
    position: absolute;
    right: 15px;
    top: 40px;
    display: block;
    width: 200px;
    padding: 0;
    font-size: 14px;
    color: #0258a1;
    border-radius: 10px;
    overflow: hidden;
}
.dropdown-menu .dropdown-item{
    cursor: pointer;
    /* padding: 7px 20px; */
}
.editor-container h4 {
    margin: 15px 0;
}
.editor-section .editor_text{
    margin-bottom: 10px;
}
/* Multiselect css start */
.multiselect_custom_css  .css-13cymwt-control{
    border: 1px solid rgba(192, 192, 192, 1);
    border-radius: 8px;
    background-color: #fff;
}
/* Multiselect css End */

.curriculum-card > .content-card-container{
    box-sizing: border-box !important;
}
.font-adjust{
    font-size: 10px !important;
}
.font-adjust-gallery{
    font-size: 10px !important;
}
.font-adjust > .react-toggle--checked .react-toggle-track-check{
    left: 8px !important;
    /* background: var(--primary); */
}
.font-adjust > .react-toggle--checked .react-toggle-track {
    background-color: var(--primary) !important;
}
.font-adjust > .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--primary) !important;
  }
.font-adjust > .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px var(--primary) !important;
    -moz-box-shadow: 0px 0px 5px 5px var(--primary) !important;
    box-shadow: 0px 0px 5px 5px var(--primary) !important;
  }
.font-adjust-gallery > .react-toggle--checked .react-toggle-track {
    background-color: var(--primary) !important;
}
.font-adjust-gallery > .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--primary) !important;
  }
.font-adjust-gallery > .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px var(--primary) !important;
    -moz-box-shadow: 0px 0px 5px 5px var(--primary) !important;
    box-shadow: 0px 0px 5px 5px var(--primary) !important;
  }
  
  .editor-section1 .text-box {
    width: 100%;
    padding: 0; /* Remove padding if present */
    margin: 0; /* Remove margin if present */
    box-sizing: border-box; /* Ensure padding and border are included in the element's width */
  }
  
  .text-box {
    border: 1px solid #eaeaea;
    /* max-width: 600px; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: #626060;
    /* margin-bottom: 15px; */
    padding: 11px;
    background: #F6F6F6;
    border-top-left-radius: var(--bs-border-radius);
    border-top-right-radius: var(--bs-border-radius);
}
 .text-box > .form-control {
    border: none;
 } 
  .header h3 {
    font-size: 18px;
    margin: 0;
  }
  
  .actions {
    display: flex;
    align-items: center;
  }
  
  .mark-demo {
    background-color: #f5f5f5;
    border: 1px solid #838383;
    border-radius: 20px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    height: 31px;
    display: flex;
    align-items: center;
  }
  .mark-demo:hover {
    background-color: var(--primary);
    color: white;
    /* border: none; */
  }
  .mark-demo span {
    margin-right: 5px;
    font-size: 16px;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #999;
  }
  
  p {
    margin: 0;
    line-height: 1.6;
    color: #555;
  }
  
  .active-demo{
    background: var(--primary);
    color: white;
    border: none;
    font-weight: 500;
}

.inactive-demo{
    color: #838383;
}
.submit-btn{
    background-color: #0258a1;
}
.dropdown-item.active, .dropdown-item:active {
    color: white;
    text-decoration: none;
    background-color: var(--primary);
}

.font-adjust .react-toggle-track {

    background-color: #838383 !important;

  }
.font-adjust-gallery .react-toggle-track {

    background-color: #838383 !important;

  }

.font-adjust .react-toggle-thumb {
    border: 1px solid #838383 !important;
 
}
.font-adjust-gallery .react-toggle-thumb {
    border: 1px solid #838383 !important;
 
}

.indexing {
    height: 62px;
    gap: 10px;
    color: black;
    font-weight: 500;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    /* border: 1px solid; */
    border-radius: 8px;
    /* border: 1px solid #C0C0C0; */
}

.content-list {
    color: #292929;
}

.subtopic-preview {
    background-color: #F9F9F9;
    border-radius: 4px;
    margin-bottom: 10px;
    color: #5B5B5B;
}

.subject-preview {
    box-shadow: 0px 1px 4px 0px #00000014;
    border-radius: 12px;
    padding-right: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.overview-container {
    background: white;
    box-shadow: 0px 7px 20px 0px #00000014;
    border-radius: 12px;
}
.chapter {
    border-radius: 8px;
}

.custom-dropdown {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    width: auto;
}

.dropdown-flex-20 {
    flex: 1;
    max-width: 20%; /* Sets Duration dropdown to 20% */
}
.dropdown-flex-33 {
    flex: 1;
    max-width: 40%; /* Each dropdown takes 33% of the parent width */
}


.dropdown-item {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #6c757d;
    transition: background-color 0.2s ease;
    gap: 5px;
}

.dropdown-item i {
    /* margin-right: 10px; */
    font-size: 18px;
    padding: 15px;
}

.dropdown-item.active {
    background-color: #eaf4ff;
    color: var(--primary);
    gap: 5px;
    /* padding: 15px; */
}

.dropdown-item.active i {
    color: var(--primary);
    padding: 15px;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
    cursor: pointer;
}


.icon-upload-files {
    background: url('/src/pages/modules/Curriculam/NewCurriculam/Icons/file-upload.svg') no-repeat center center;
    width: 20px;
    height: 20px;
}
.close-btn svg:hover {
stroke: var(--primary);
}
.topic-upload {
    border: none;
    background-color: var(--primary);
    color: white;
    border-radius: 4px;
    /* font-weight: 500; */
    width: 210px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.topic-active {
    border: 1px solid var(--primary);
    background-color: white;
    font-weight: 500;
    color: var(--primary);
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 7px;
    justify-content: center;
    width: 210px;
    font-size: 12px;
}

 /* 1206 828 */
 @media only screen and (max-width: 1206px) {
    .main_curriculum_section {
      flex-direction: column-reverse;
    }
    .new-curriculam-first-part-section {
        min-width: 100%;
    max-width: 100%;
    }
  }

  .file-view {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    margin-bottom: 10px;
    position: relative;
  }
  .view-component-parent-component{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}
.view-component-parent-component .content-div{
    flex: 1;
}
.doc-viewer-component {
    display: flex;
    height: 90vh;
    width: 98%;
    overflow: auto;
}
.css-1g04pbp-MuiModal-root {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 80% !important; */
}
.css-1sucic7 {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* width: 80% !important; */
}

.text-box > .quill > .ql-container.ql-snow {
    background-color: #ffffff;
    color: #000;
    height: 200px;
    border: 1px solid #ccc;
    padding: 8px;
    overflow-y: auto;
  }
  
  /* Styles for the quill editor toolbar */
  .text-box > .quill > .ql-toolbar.ql-snow {
    background: none;
    /* border-bottom: 1px solid #ccc; */
    border: none !important;
  }
  
  /* Additional button styling within toolbar */
  .text-box > .quill > .ql-toolbar.ql-snow .ql-formats button {
    color: #333;
    margin-right: 4px;
  }
  .custom-modal-style {
    width: 897px;
    height: 688px;
    }
    .container > .modal > .modal-dialog {
        max-width: 90%; /* Optional: Adjust as needed */
    }
/* CSS file */
.file-container-row {
    display: flex;
    flex-wrap: wrap;
    gap: 27px;
}

.file-item {
    flex: 1 1 48%; /* 48% width for two files per row with space */
    max-width: 48%;
}

.full-width {
    flex: 1 1 100%;
    max-width: 100%;
}

