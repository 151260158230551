.custom-select-btn{
    display: flex;
    align-items: center;
    border: 1.5px solid var(--primary);
    color: var(--primary);
    font-size: 18px;
    font-weight: 500;
    padding: 7px;
    cursor: pointer;
    background-color: #fff;
}
.custom-select-btn .active{
    background-color: var(--primary);
    color: white;
}
.custom-select-btn-primary{
    cursor: pointer;
    display: flex;
    align-items: center;
    /* border: 1.5px solid var(--primary); */
    color: white;
    background-color: var(--primary);
    font-size: 12px;
    font-weight: 400;
    padding: 5px;
}
.custom-select-btn-secondary{
    cursor: pointer;
    display: flex;
    align-items: center;
    /* border: 1.5px solid var(--primary); */
    color: var(--primary);
    background-color: white;
    font-size: 12px;
    font-weight: 400;
    height: 100%;
    padding: 5px;
}