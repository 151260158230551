.school-name h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #0258a1;
}

.school-name p {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
}

.school-request-section span {
    font-weight: 500;
    font-size: 16px;
    color: #838383;

}

.approved-container-or-reject .btn {
    width: 106px;
    padding: 2px 10px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 100px;
    color: white;
}

.approved-container-or-reject .home-status-pending-color {
    background-color: var(--primary);
}

.approved-container-or-reject .home-status-assign-color {
    background: #3DD598;
}

.approved-container-or-reject .home-status-rejected-color {
    background: #EC6666;
}
.approved-container-or-reject .active-btn{
    background-color: var(--primary);
}
.approved-container-or-reject .disable-btn{
background-color: #d6d6d6;
}