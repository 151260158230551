.filter-btn-container {
    font-size: 16px;
    color: rgba(91, 91, 91, 1);
    background-color: rgba(201, 201, 201, 0.2);
    width: 107px;
    height: 43px;
    display: flex;
    justify-content: center;
    gap: 7px;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
}

.filter-dropbox {
    z-index: 99;
    /* width: 618px; */
}

.filter-dropbox .filter-list-container {
    display: flex;
    max-height: 332px;
}

/* YourComponent.css */
.filter-list-container {
    /* padding: 10px; */
}

.filter-list-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    max-height: 300px;
}
.filter-list-container li {
    margin-right: 10px;
    padding: 8px 12px;
    margin-bottom: 8px;
    cursor: pointer;
    border-radius: 5px;
    color: rgba(131, 131, 131, 1);
}
.filter-list-container .actice-btn {
    background-color: #0258a1;
    color: white;
}
.filter-list-container .actice-btn:hover{
    background-color: #0258a1;
}
.filter-list-container li:hover {
    background-color: rgba(77, 142, 255, 0.5);
    color: white;
}

.filter-dropbox .title{
    font-size: 16px;
    font-weight: 500;
    color: rgba(131, 131, 131, 1);
}