.primary-button {
    border: none;
    color: white !important;
    padding: 12px 15px !important;
    /* height: 40px !important; */
    transition: box-shadow 0.5s, background-color 0.5s;
    box-shadow: #dddddd 0px 4px 24px 0px;
    font-weight: 400;
    font-size: 16px;
}

.primary-button:hover {
    box-shadow: none;
    cursor: pointer;
}

.primary-button-disabled {
    border: none;
    color: #f0f0f0 !important;
    padding: 12px 15px !important;
    /* height: 40px !important; */
    font-size: 16px;
}

.secondary-button {
    background-color: transparent !important;
    border: 1px solid #0258a1 !important;
    color: #0258a1;
    padding: 12px 15px !important;
    /* height: 40px !important; */
    transition: box-shadow 0.5s, background-color 0.5s;
    font-weight: 400;
    font-size: 16px;
}

.secondary-button-disabled {
    opacity: 0.5;
    background-color: transparent !important;
    border: 1px solid #0258a1 !important;
    color: #0258a1;
    padding: 12px 15px !important;
    /* height: 40px !important; */
    font-size: 16px;
}

.secondary-button:hover {
    cursor: pointer;
}