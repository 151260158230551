.top-school-section p{
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    text-align: left;
    margin: 0;
}

.EmptyData-heading {
    font-size: 16px;
    font-weight: 500;
    color: #838383;
}
.EmptyData-subheading {
    font-size: 12px;
}