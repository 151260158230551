.clearBtn {
    width: 89px;
    display: flex;
    align-items: center;
    border-radius: 45px;
    border: 2px solid #649DFF;
    color: #649DFF;
    font-size: 16px;
    font-weight: 400;
    padding: 8px 10px 8px 10px;
    gap: 6px;
    height: 37px;
    transition: all .4s;
}

.clearBtn:hover {
    background-color: #649DFF;
    color: white;
    transition: all .4s;
}

.deleteIcon {
    fill: white;
}
.custom-icon{
    fill: red !important;
    color: red !important;
    /* background-color: red; */
}