.css-1u9des2-indicatorSeparator{
    display: none;
}
.Dropdown-container .css-13cymwt-control{
height: 56px;
border-radius: 10px;
}
.Dropdown-container .css-t3ipsp-control{
height: 56px;
border-radius: 10px;
}