.parent-container-btn-tab {
    font-size: 22px;
    border-radius: 7px !important;
    width: fit-content;
    height: 55px;
    display: flex !important;
    align-items: center;
    box-shadow: rgb(34 41 47 / 10%) 0px 4px 24px 0px !important;
}

.child-one-btn-tab {
    width: fit-content !important;
    height: 100%;
    display: flex;
    align-items: center;

    cursor: pointer !important;
}

.child-one-btn-tab .total-count{
  color: #656565;
  height: 37px;
  width: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.child-two-btn-tab {
    font-weight: 500 !important;
    margin: 0px 10px !important;
    padding: 8px 5px !important;
}

.child-two-btn-tab-condition:hover {
    border-bottom: 2px solid var(--primary) !important;
    color: var(--primary) !important;
}

.child-two-btn-tab-condition:hover {
    position: relative;
    top: -1px;
}