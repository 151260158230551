.invoice-list-dataTable .rdt_TableHeadRow {
  background-color: #F5F5F5;
  font-size: 16px;
  font-weight: 500;
  color: #656565;
}

.invoice-list-dataTable .rdt_TableBody .rdt_TableRow {
  font-size: 14px;
  font-weight: 400;
  color: #8E8E8E;
}

/* pagination */
/* Your existing CSS */

/* Add the following styles for rounded buttons and active index */
.pagination .page-item a {
  border-radius: 50%;
  border: none;
  color: #838383;
  font-size: 12px;
}
.pagination .page-item.active a {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  color: white;
}

.pagination .page-item {
  /* padding-inline: 5px; */
}
.pagination .page-item.active a:hover {
  background-color: #0258a1;
}
.pagination .page-item.active a:focus {
  box-shadow: none;
}
.pagination .page-item a:hover{
background-color: inherit;
}

.pagination .page-link {
  background-color: none;
}

.pagination .prev,
.pagination .next {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #0258a1;
  /* Customize background color */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 10px !important;
}
 .active > a {
  background-color: var(--primary) !important;
}
/* Remove border around pagination links */