.sub-module-category > .custom-input-wrapper {
    display: flex;
      /* justify-content: center; */
      align-items: center;
      gap: 10px;
  }
.crud-operations > .custom-input-wrapper {
    display: flex;
      /* justify-content: center; */
      align-items: center;
      gap: 10px;
  }