.hover-table-option-style {
    cursor: pointer !important;
    border: 0px !important;
    text-decoration: none !important;
}
.on-hover-dropdown-list{
    color: white;
}
.on-hover-dropdown-list:hover{
    color: var(--primary);
}

  