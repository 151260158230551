.left-arrow-image {
  width: 41px;
  height: 41px;
  border-radius: 8px;
  cursor: pointer;
}

.left-arrow-image h2 {
  font-size: 25px;
  font-weight: 400;
  line-height: 37px;
}

.curriculum-list .react-toggle-track {
  height: 24px !important;
  width: 75px;
}

.curriculum-list .react-toggle-thumb {
  height: 17px;
  width: 17px;
}

.curriculum-list .react-toggle--checked .react-toggle-thumb {
  left: 53px;
}

.curriculum-list .react-toggle-track-x {
  left: 25px;
}

.curriculum-list .react-toggle-track-check {
  left: 10px;
}

/* .curriculum-list .dropdown{
  position: unset !important;
}
.curriculum-list .khorWA{
  position:unset !important;
} */

/* Tabs css start*/
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #0258a1;
  color: #fff;
  border: 1px solid #0258a1;
}

.form_btn {
  background-color: #0258a1;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  width: 210px;
}

/* Tabs css end*/

.curriculum-card-container > .curriculum-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 350px;
  height: 155px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.card-content {
  display: flex;
  align-items: center;
}

.icon {
  background-color: #f0f8ff;
  padding: 10px;
  border-radius: 8px;
  margin-right: 15px;
}

.details h3 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.details p {
  color: #9a9a9a;
  font-size: 12px;
  margin-top: 5px;
}

.view-button {
  margin-top: 20px;
  padding: 10px;
  background-color: #EAF5FF;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: #0258a1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: #cce0ff;
}

.no-shadow {
  box-shadow: none !important;
}

.details {
  line-height: 20px;
}

.curriculum-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
