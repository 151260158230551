/* .aside-bar-container {
    display: flex;
    background-color: #FAFAFA;
} */

.aside-bar {
    width: 300px;
    height: 100%;
    background-color: #ffffff;
    /* background-color:rgba(126, 172, 157, 0.637); */
    margin-right: 2rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    transition: all .7s;
    /* align-items: center;
    justify-content: center; */
    box-shadow: 0px 4px 18px 0px #0000001A;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
}

.margin-right {
    margin-right: 20rem;
    transition: all .7s;

}

.expandable:hover {
    width: 350px;
    height: 100vh;
    background-color: #ffffff;
    /* background-color:rgba(126, 172, 157, 0.637); */
    margin-right: 2rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    transition: all .7s;
    /* align-items: center;
    justify-content: center; */
    box-shadow: 0px 4px 18px 0px #0000001A;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
}

.margin-right-not {
    margin-right: 8rem;
    transition: all .7s;
}

.hide {
    width: 7rem;
    transition: all .7s;
}

.sideBarList {
    transition: all 0.3s;
}

.sideBarList:hover {
    transform: translateX(5px);
}

.aside-bar.open {
    width: 450px;
    max-width: 450px;
}

.toggle-button {
    background: none;
    border: none;
    color: #a22e2e;
    font-size: 1.5rem;
    cursor: pointer;
}

.content {
    padding: 15px;
}

.content .menu-title {
    font-size: 12px;
    font-weight: 400;
    color: #838383;
    padding-left: 10px;
}

.end-profile-section-sidebar {
    /* background-color: #a22e2e; */
    color: #0258a1;
    position: relative;
    cursor: pointer;
    /* bottom: 0;
    left: 0;
    right: 0; */
    /* left: 2rem; */
}

.end-profile-section-sidebar .profile-container {
    position: fixed;
    bottom: 0;
    width: 300px;
    border-top: 0.5px solid #B8B8B8;
    transition: all .7s;
    padding: 20px;
}

.hide .end-profile-section-sidebar .profile-container {
    display: block;
    width: 7rem;
    border-top: 0.5px solid #B8B8B8;
    transition: all .7s;
}

.profile-name {
    font-size: 18px;
    font-weight: 400;
    color: #1D1D17;

}

.profile-short-name {
    font-size: 13px;
    font-weight: 400;
    color: #C4C4C4;

}

.profile-image-section {
    width: 57px;
    height: 57px;
    border: 4px solid #0258a1;
    border-radius: 50%;
    padding: 2px;
    transition: all .7s;
}

.profile-image-section img {
    height: 100%;
    width: 100%;
}

.status-profile {
    background-color: #EDF4FF;
    border-radius: 10px;
}

.complete-percentage {
    font-size: 16px;
    font-weight: 600;
}

.complete-profile-setup {
    font-size: 11px;
    font-weight: 400;
    color: #838383;

}

@media only screen and (max-width: 1200px) {
    .aside-bar-container {
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        /* background-color: #a22e2e; */
    }

    .hide {
        transition: all .7s;
        width: 0;
    }

    .hide .end-profile-section-sidebar {
        display: none;
    }

    .hide .end-profile-section-sidebar .profile-container {
        width: 0;
        border-top: none;
        transition: all .7s;
    }

    .hide .end-profile-section-sidebar .profile-container .profile-image-section {
        width: 0;
        height: 57px;
        border: none;
        /* border-radius: 0; */
        padding: 2px;
        transition: all .7s;
    }

}
.submenu {
    display: none; /* Hide submenu by default */
    padding-left: 1rem; /* Indent submenu items */
  }
  
  .submenu.open {
    display: block; /* Show submenu when open */
    
  }
  